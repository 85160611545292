import "./BundleCard.css";
import CardDivider from "./CardDivider";

import { GoCreditCard } from "react-icons/go";
import { CiClock2 } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const BundleCard = ({bundle, deviceNumber}) => {
  const navigate = useNavigate()

  const handleNavigate = () => {

    if(deviceNumber.length == 11){
      console.log("working", typeof(bundle))
      localStorage.setItem('bundle', JSON.stringify(bundle));
      navigate(`bundle/${bundle.BillerId}`)
      return
    }

    toast.error("Device number should be atleast 11")
  }

  return (
    <div className="bundle_card_container">
      <div className="bundle_card_wrapper">
        <div className="bundle_card_upper">
          <p className="p_b">{bundle.Name}</p>
        </div>

        {/* <div className="card_divider_absolute">
          <CardDivider />
        </div> */}

        <div className="bundle_card_lower">
          {/* <div className="validity_container">
            <div>
              <CiClock2 />
              <p>Validity</p>
            </div>
            <p className="p_b">30 days</p>
          </div> */}
          <div className="price_buy_container">
            {/* <div>
              <div>
              <GoCreditCard />
              <p>Price</p>
              </div>
              <p className="p_b">160,000</p>
            </div> */}
            <button onClick={handleNavigate}>Buy now</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BundleCard;
