import { useEffect, useState } from "react";
import axios from "axios";
import "../MerchantModal/MerchantModal.css";
import "./ChatUs.css";
import CallIcon from "../../assets/call-icon.png";
import { IoCloseCircle } from "react-icons/io5";
import { IoLogoWhatsapp, IoLogoLinkedin } from "react-icons/io";
import { BsTwitterX } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { useLocalState } from "../../utils/useLocalStorage";
import SuccessScreen from "../SuccessScreenComponent/SuccessScreen";

const ChatUs = ({ onClick }) => {
  const [jwt] = useLocalState("", "jwt");
  const [isVisible, setIsVisible] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const defSelect = "Select Subject";
  const [formInfo, setFormInfo] = useState({
    full_name: "",
    email: "",
    subject: defSelect,
    message: "",
  });

  const [fullNameError, setFullNameError] = useState("");
  const [emailValidityMessage, setEmailValidityMessage] = useState("");
  const [selectedMessage, setSelectedMessage] = useState("");

  const validEmail =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const onChange = (event) => {
    const { name, value } = event.target;
    setFormInfo((prevFormInfo) => ({
      ...prevFormInfo,
      [name]: value,
    }));
  };

  // const submit = (event) => {
  //   event.preventDefault();
  //   let valid = true;

  //   // Name validation
  //   if (formInfo.fullName.length === 0) {
  //     setFullNameError("Name is required");
  //     valid = false;
  //   } else {
  //     setFullNameError("");
  //   }

  //   // Email validation
  //   if (!formInfo.email.match(validEmail)) {
  //     setEmailValidityMessage(
  //       formInfo.email.length === 0
  //         ? "Email is required"
  //         : "Invalid Email Address"
  //     );
  //     valid = false;
  //   }
  //   // } else {
  //   //   setEmailValidityMessage("Valid Email Address");
  //   // }

  //   // Select Options validation
  //   if (formInfo.help === defSelect && formInfo.message.length < 2) {
  //     setSelectedMessage("Select a category or leave a detailed message");
  //     valid = false;
  //   } else {
  //     setSelectedMessage("");
  //   }

  //   if (valid) {
  //     console.log("Form submitted", formInfo);
  //     // Form submission logic here
  //     axios
  //       .post(
  //         "https://leverpay-api.azurewebsites.net/api/v1/contact-us",
  //         formInfo,
  //         {
  //           headers: {
  //             "Content-Type": "multipart/form-data",
  //             Authorization: `Bearer ${jwt}`,
  //           },
  //         }
  //       )
  //       .then((response) => {
  //         console.log("Response received", response);
  //         alert(response);
  //       })
  //       .catch((error) => {
  //         console.log("Error Occured", error);
  //       });
  //   }
  // };
  const submit = (event) => {
    event.preventDefault();
    let valid = true;

    // Name validation
    if (formInfo.full_name.length === 0) {
      setFullNameError("Name is required");
      valid = false;
    } else {
      setFullNameError("");
    }

    // Email validation
    if (!formInfo.email.match(validEmail)) {
      setEmailValidityMessage(
        formInfo.email.length === 0
          ? "Email is required"
          : "Invalid Email Address"
      );
      valid = false;
    }

    // Select Options validation
    if (formInfo.subject === defSelect && formInfo.message.length < 2) {
      setSelectedMessage("Select a category or leave a detailed message");
      valid = false;
    } else {
      setSelectedMessage("");
    }

    if (valid) {
      axios
        .post(
          "https://leverpay-api.azurewebsites.net/api/v1/contact-us",
          formInfo,
          {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          }
        )
        .then((response) => {
          console.log("Response received", response.data);
          // alert(response.data.message);
          setSuccessOpen(true);
          setResponseMessage(response.data.message);

          setFormInfo({
            full_name: "",
            email: "",
            subject: defSelect,
            message: "",
          });

          setFullNameError("");
          setEmailValidityMessage("");
          setSelectedMessage("");
        })
        .catch((error) => {
          console.error("Error Occurred", error);

          if (error.response) {
            console.log("Response data:", error.response.data.message);
            console.log("Response status:", error.response.status);
          }
        });
    }
  };

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div className="chatus-modal-container">
      <div className={`chatus-modal ${isVisible ? "scale-in" : "scale-out"}`}>
        <div className="merchant-modal-close-icon">
          <IoCloseCircle
            size={24}
            style={{ color: "black" }}
            onClick={() => {
              setIsVisible(false);
              setTimeout(onClick, 300);
            }}
          />
        </div>
        <h2 className="chatus-modal-title">Chat Us</h2>
        <div className="chatus-modal-section">
          <div className="merchant-modal-main">
            <div className="chatus-modal-icon">
              <img src={CallIcon} alt="call icon" />
            </div>
            <div className="merchant-modal-cardInfo">
              <h3>Chat with us</h3>
              <span>Send us a message now</span>
            </div>
          </div>
          <div className="chatus-modal-button">
            <p>+234 9060898687</p>
            <div className="social-icons">
              <IoLogoWhatsapp size={20} style={{ color: "#1FAF38" }} />
              <BsTwitterX />
              <IoLogoLinkedin size={20} style={{ color: "#047dff" }} />
            </div>
          </div>
          <div className="chatus-modal-mailus">
            <div className="chatus-modal-mail">
              <h3>Email us</h3>
              <span>contact@leverpay.com</span>
              <p>Reply within 24hrs working day</p>
            </div>
            <span className="icon">
              <MdEmail size={20} style={{ color: "#E07E61" }} />
            </span>
          </div>
        </div>
        <div className="chatus-modal-form">
          <h2>Feedback</h2>
          <div className="chatus-modal-form-container">
            <form onSubmit={submit}>
              <section className="chatus-input-container">
                <div className="name">
                  <input
                    name="full_name"
                    type="text"
                    id="full_name"
                    placeholder="Name"
                    onChange={onChange}
                    value={formInfo.full_name}
                  />
                  <span
                    className="valid"
                    style={{ color: fullNameError ? "red" : "" }}
                  >
                    {fullNameError}
                  </span>
                </div>
                <div className="email_con">
                  <input
                    name="email"
                    type="email"
                    id="email"
                    placeholder="mail@mail.com"
                    onChange={onChange}
                    value={formInfo.email}
                  />
                  <span
                    className="valid"
                    style={{
                      color:
                        emailValidityMessage === "Valid Email Address"
                          ? "green"
                          : "red",
                    }}
                  >
                    {emailValidityMessage}
                  </span>
                </div>
              </section>

              <select
                className="chatus-select-option"
                value={formInfo.subject}
                onChange={onChange}
                id="subject"
                name="subject"
                required
              >
                <option value="Select Subject">{defSelect}</option>
                <option value="paymentIssue">Payment Issue</option>
                <option value="upgradeCard">Unable to Upgrade Card</option>
                <option value="delayedDeposit">Delayed Deposit of Funds</option>
              </select>
              <div className="textarea-container">
                <textarea
                  name="message"
                  placeholder="Leave a message"
                  id="message"
                  maxLength="500"
                  rows={4}
                  onChange={onChange}
                  value={formInfo.message}
                />
                <span
                  className="valid"
                  style={{ color: selectedMessage ? "red" : "" }}
                >
                  {selectedMessage}
                </span>
              </div>
              <div className="submitCon">
                <button type="submit" className="submit-button">
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {successOpen && (
        <SuccessScreen onClose={() => setSuccessOpen(false)}>
          {responseMessage}
        </SuccessScreen>
      )}
    </div>
  );
};

export default ChatUs;
