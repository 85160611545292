import { useNavigate } from "react-router-dom";
import "./InternetOptions.css";
import { IoIosArrowBack } from "react-icons/io";
import { useEffect, useState } from "react";
import BundleCard from "./BundleCard";
import {
  getBillerPaymentItem,
  getBillersByCategoryId,
} from "../../../services/apiService";
import { useLocalState } from "../../../utils/useLocalStorage";
import blue_loader from "../../../assets/blue_loader.gif";

const InternetOptions = () => {
  const navigate = useNavigate();
  const internetId = "5";

  const [jwt, setJwt] = useLocalState("", "jwt");
  const [internetOptions, setInternetOptions] = useState(null);
  const [billerId, setBillerId] = useState(null);
  const [isLoading, setIsloading] = useState(false);
  const [deviceNumber, setDeviceNumber] = useState("");

  // State to hold the fetched bundle options
  const [bundles, setBundles] = useState([]);

  // useEffect to simulate fetching data based on the active option
  useEffect(() => {
    // Simulate a network request (fetching from backend)
    const fetchBundles = async () => {
      setIsloading(true);
      try {
        const response = await getBillerPaymentItem(billerId, jwt);

        setBundles(response);

        console.log(response);
        setIsloading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchBundles();
  }, [billerId]);

  useEffect(() => {
    const fetchInternetOptions = async () => {
      try {
        const response = await getBillersByCategoryId(jwt, internetId);

        setInternetOptions(response[0].Billers);
        setBillerId(response[0].Billers[0].Id);
      } catch (error) {
        console.log(error);
      }
    };

    fetchInternetOptions();
  }, []);

  const handleChange = (e) => {
    const value = e.target.value;

    if (isNaN(value)) {
      return;
    }
    setDeviceNumber(value);
  };

  return (
    <div className="internet_options_card_container">

      <div className="transfer-header">
        <button onClick={() => navigate(-1)}>
          <IoIosArrowBack />
        </button>
        <h1>Network</h1>
        <div className="not-needed"></div>
      </div>

      <div className="internet_options_container">
        <div className="option_field_container">
          <label htmlFor="provider">Provider</label>
          <select
            name="provider"
            id="provider"
            onChange={(e) => setBillerId(e.target.value)}
          >
            {internetOptions &&
              internetOptions.map((option, index) => (
                <option value={option.Id} key={index}>
                  {option.Name}
                </option>
              ))}
          </select>
        </div>

        <div className="option_field_container">
          <label htmlFor="device-number">Device Number</label>
          <input
            type="text"
            id="device-number"
            placeholder="00845678934"
            value={deviceNumber}
            onChange={handleChange}
          />
        </div>

        <div className="options_main_container">
          {/* Render the fetched bundle options */}
          {isLoading ? (
            <div className="loader_wrapper">
              <img src={blue_loader} alt="gif loader" />
            </div>
          ) : (
            <div className="options_content_wrapper">
              <div className="options_content">
                {bundles.length > 0 ? (
                  bundles.map((bundle) => (
                    <BundleCard
                      key={bundle.id}
                      bundle={bundle}
                      deviceNumber={deviceNumber}
                    />
                  ))
                ) : (
                  <p>Loading bundle options...</p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InternetOptions;
