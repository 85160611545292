import React, { useState, useEffect } from "react";
import "./InternetPage.css";
import LoadingScreen from "../../../components/ReusableComponents/LoadingPage/LoadingScreen";
import InternetOptions from "../../../components/PayBillsCompontent/InternetComponent/InternetOptions";
import InternetBundle from "../../../components/PayBillsCompontent/InternetComponent/InternetBundle";

const InternetPage = () => {
  // State to track loading status
  const [isLoading, setIsLoading] = useState(true);

  // useEffect to simulate a 3-second loading screen
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className="internet_page">
      <div className="internet_card_container">
          <div className="internet_full_screen">
            <InternetOptions />
          </div>
      </div>
    </div>
  );
};

export default InternetPage;
