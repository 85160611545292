import React from 'react'
import "./FailedCard.css"
import failed_img from "../../../assets/images/confirm_failed.png"
import { useNavigate } from 'react-router-dom'

const FailedCard = ({setFail}) => {
  const navigate = useNavigate()

  const handleDone = () => {
    setFail(false)
    navigate("/")
  }

  return (
    <div className='general__backdrop'>
      <div className='general__failed_card'>
        <div className='img__group'>
          <img src={failed_img} alt="failed logo" />
          <p>Transaction Failed</p>
          <p className='try_p'>Try Again</p>
        </div>

        <div className='button__wrapper'>
          <button onClick={handleDone}>Try Again</button>
        </div>
      </div>
    </div>
  )
}

export default FailedCard
