import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./GetHelp.css";
import "../SettingsComponent/Settings.css";
import { IoIosArrowBack } from "react-icons/io";
import SettingsCard from "../SettingsComponent/SettingsCards";
import CallIcon from "../../assets/call-icon.png";
import { TbLockFilled } from "react-icons/tb";
import ChatIcon from "../../assets/chat-icon.png";
// import FAQIcon from "../../assets/faq-icon.png";
import CallUs from "../CallUs/CallUs";
import ChatUs from "../ChatUs/ChatUs";
// import { useNavigate } from "react-router-dom";

const GetHelp = ({ onClick }) => {
  const navigate = useNavigate();
  const [showCallUsModal, setShowCallUsModal] = useState(false);
  const [showChatUsModal, setShowChatUsModal] = useState(false);

  const CardList = [
    {
      key: 1,
      CardTitle: "Call Us",
      CardDetails: "Contact Call Center",
      cardIcon: CallIcon,
      imgColor: "#18B30A",
      onClick: () => {
        setShowCallUsModal(true);
      },
    },
    {
      key: 2,
      CardTitle: "Chat with us",
      CardDetails: "Send us Message now",
      cardIcon: ChatIcon,
      imgColor: "#3AD4A7",
      onClick: () => {
        setShowChatUsModal(true);
      },
    },
    // {
    //   key: 3,
    //   CardTitle: "FAQs",
    //   CardDetails: "Frequently asked Questions",
    //   cardIcon: FAQIcon,
    //   imgColor: "#000000",
    // },
  ];

  const handleBackButton = () => {
    navigate(-1);
  };
  return (
    <div className="helpPage">
      <div className="help-page-container">Help</div>
      <div className="help-container">
        <div className="help-header">
          <IoIosArrowBack
            size={20}
            onClick={handleBackButton}
            className="help-header-back-icon"
          />
          <span>Get Help</span>
          <span></span>
        </div>
        <div className="help-cards-holder">
          {CardList.map((item, i) => (
            <SettingsCard
              key={i}
              title={item.CardTitle}
              info={item.CardDetails}
              color={item.imgColor}
              icon={item.cardIcon}
              onClick={item.onClick}
            />
          ))}
        </div>
      </div>
      <div className="settings-footer">
        <span>
          <TbLockFilled
            size={20}
            style={{ color: "white", marginTop: "-7px" }}
          />
        </span>{" "}
        Secured By LeverChain Technology
      </div>
      {showCallUsModal && (
        // <div className="modal-overlay">
        <CallUs onClick={() => setShowCallUsModal(false)} />
        // </div>
      )}
      {showChatUsModal && (
        // <div className="modal-overlay">
        <ChatUs onClick={() => setShowChatUsModal(false)} />
        // </div>
      )}
    </div>
  );
};
export default GetHelp;
