import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LoadingScreen from "../../../components/ReusableComponents/LoadingPage/LoadingScreen";
import "./BundlePage.css";
import InternetBundle from "../../../components/PayBillsCompontent/InternetComponent/InternetBundle";

const BundlePage = () => {
  const { id } = useParams();

  // State to track loading status
  const [isLoading, setIsLoading] = useState(true);
  const [bundle, setBundle] = useState(null);

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('bundle'));
    console.log(storedUser)
    setBundle(storedUser)
  }, [])


  // useEffect to simulate a 3-second loading screen
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className="internet_page">
      <div className="internet_card_container">
        <div className="internet_full_screen">
          <InternetBundle bundle={bundle}/>
        </div>
      </div>
    </div>
  );
};

export default BundlePage;
