import "../SignupComponent.css";
import { IoIosCheckmarkCircle } from "react-icons/io";
import StatusBar from "./StatusBar";

const Validator = ({
  lowerValidated,
  upperValidated,
  numberValidated,
  specialValidated,
  lengthValidated,
  password
}) => {
  return (
    <div className="password_tracker">
      <div className={lowerValidated ? "validated" : "not-validated"}>
        <IoIosCheckmarkCircle />
        At least one lowercase letter
      </div>
      <div className={upperValidated ? "validated" : "not-validated"}>
        <IoIosCheckmarkCircle />
        At least one uppercase letter
      </div>
      <div className={numberValidated ? "validated" : "not-validated"}>
        <IoIosCheckmarkCircle />
        At least one number
      </div>
      <div className={specialValidated ? "validated" : "not-validated"}>
        <IoIosCheckmarkCircle />
        At least one special character
      </div>
      <div className={lengthValidated ? "validated" : "not-validated"}>
        <IoIosCheckmarkCircle />
        At least 8 characters
      </div>

      <StatusBar password={password} />
    </div>
  );
};

export default Validator;
