import React, { useEffect, useState } from "react";
import FieldVerifyModal from "../../components/Verification/FieldVerifyModal/FieldVerifyModal";
import "./KycVerification.css";
import Step from "../../components/Verification/FieldVerifyModal/Step";
import CameraField from "../../components/Verification/Camera/CameraField";
import SuccessfullModal from "../../components/Verification/Modal/SuccessfullModal";
import { toast, ToastContainer } from "react-toastify";
import { useLocalState } from "../../utils/useLocalStorage";
import {
  emailVerification,
  fetchVerificationStatus,
  phoneVerification,
  bvnVerification,
  verifyOtp,
  ninVerification,
  addressVerification,
} from "../../services/kycService";

const KycVerification = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [refreshKey, setRefreshKey] = useState(0); // Trigger for refreshing status
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [otp, setOtp] = useState(new Array(6).fill("")); // OTP state
  const [isOtpEntered, setIsOtpEntered] = useState(false); // State to switch between OTP and confirmation
  const [fieldToVerify, setFieldToVerify] = useState(""); // Track the field being verified
  const [openCamera, setOpenCamera] = useState(false);
  const [isImageCaptured, setIsImageCaptured] = useState(false);
  const [utilitySrc, setUtilitySrc] = useState(null);
  const [utility64, setUtility64] = useState(null);
  const [submit, setSubmit] = useState(false);

  const [status, setStatus] = useState(null);

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [bvn, setBvn] = useState("");
  const [nin, setNin] = useState("");
  const [address, setAddress] = useState("");

  const [fieldMsg, setFieldMsg] = useState("");

  const [verifiedSteps, setVerifiedSteps] = useState({
    email: (status && status.email === "verified") || false,
    bvn: false,
    nin: false,
    phoneNumber: (status && status.phone === "verified") || false,
    profileImage: false,
    address: false,
  });

  const [jwt] = useLocalState("", "jwt");

  useEffect(() => {
    const fetchStatusAsync = async () => {
      const response = await fetchVerificationStatus(jwt);
      if (response.success) {
        setStatus(response.data);
        console.log(response.data);
      }
    };
    fetchStatusAsync();
  }, [jwt, refreshKey]);

  // Update the current step based on sequential verification
  useEffect(() => {
    if (status) {
      // Ensure steps progress in order
      if (status.email === "verified") {
        if (status.phoneNumber === "verified") {
          if (status.bvn === "verified") {
            if (status.nin === "verified") {
              if (status.profileImage === "verified") {
                if (status.address === "verified") {
                  setCurrentStep(7); // All fields verified
                } else {
                  setCurrentStep(6);
                }
              } else {
                setCurrentStep(5);
              }
            } else {
              setCurrentStep(4);
            }
          } else {
            setCurrentStep(3);
          }
        } else {
          setCurrentStep(2);
        }
      } else {
        setCurrentStep(1); // Start at step 1 if nothing is verified
      }
    }
  }, [status]);

  const handleVerifyEmail = async (email) => {
    const regex = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
    const validEmail = regex.test(email);

    if (!validEmail) {
      toast.error("Enter valid email address!");
      return;
    }

    try {
      const response = await emailVerification(email, jwt);
      console.log(response);

      if (response.success) {
        setFieldToVerify("email");
        setFieldMsg(email);
        setIsModalOpen(true);
      }
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  };

  const handlePhoneChange = (e) => {
    const { value } = e.target;

    if (isNaN(value) || value.length > 11) {
      return;
    }
    setPhone(value);
  };

  const handleVerifyPhone = async (phone) => {
    // let modifiedPhone

    if (phone.length != 11) {
      toast.error("Enter complete 11 digits");
      return;
    }

    // Check if the phone number starts with '0' and replace it with '234'
    // if (phone.startsWith("0")) {
    //   modifiedPhone = `234${phone.slice(1)}`;
    // }

    toast.loading("Loading...");
    try {
      const response = await phoneVerification(phone, jwt);

      if (response.success) {
        setFieldToVerify("phoneNumber");
        setFieldMsg(phone);
        setIsModalOpen(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleBvnChange = (e) => {
    const { value } = e.target;

    if (isNaN(value)) {
      return;
    }
    setBvn(value);
  };

  const handleVerifyBvn = async (bvn) => {
    if (bvn.length != 11) {
      toast.error("Enter exactly 11 bvn digits");
      return;
    }

    toast.loading("Loading...");
    try {
      const response = await bvnVerification(bvn, jwt);

      if (response.success) {
        setFieldToVerify("bvn");
        setFieldMsg(bvn);
        setIsOtpEntered(true);
        setIsModalOpen(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleNinChange = (e) => {
    const { value } = e.target;

    if (isNaN(value)) {
      return;
    }
    setNin(value);
  };

  const handleVerifyNin = async (nin) => {
    if (bvn.length != 11) {
      toast.error("Enter exactly 11 nin digits");
      return;
    }

    toast.loading("Loading...");
    try {
      const response = await ninVerification(nin, jwt);

      if (response.success) {
        setFieldToVerify("nin");
        setFieldMsg(nin);
        setIsOtpEntered(true);
        setIsModalOpen(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOtpChange = (e, fieldIndex) => {
    if (isNaN(e.target.value)) return false;

    setOtp([
      ...otp.map((data, index) =>
        index === fieldIndex ? e.target.value : data
      ),
    ]);

    if (e.target.value && e.target.nextSibling) {
      e.target.nextSibling.focus();
    }
  };

  const handleConfirmOtp = async () => {
    if (otp.every((digit) => digit !== "")) {
      // Move to the confirmation step
      // handleContinue();
      const joinedOtp = otp.join("");
      try {
        let response = await verifyOtp(fieldToVerify, joinedOtp, jwt);
        console.log(response);

        if (response.success) {
          setIsOtpEntered(true);
        }
      } catch (error) {
        console.log(error);
        toast.error(error);
      }
    } else {
      console.log("Didnt work");
    }
  };

  const handleContinue = () => {
    if (fieldToVerify === "profileImage" && isImageCaptured) {
      // Mark profile image as verified
      setVerifiedSteps({ ...verifiedSteps, profileImage: true });
      setOpenCamera(false);
      setIsModalOpen(true); // Open the modal for confirmation
      setIsOtpEntered(true); // Directly move to the verification status step
    } else if (isOtpEntered) {
      setIsModalOpen(false);

      // Mark the current step as verified based on the field being verified
      const fieldMap = {
        email: "email",
        phoneNumber: "phoneNumber",
        bvn: "bvn",
        nin: "nin",
        profileImage: "profileImage",
        address: "address",
        UtilityBill: "utility",
      };

      setVerifiedSteps({ ...verifiedSteps, [fieldMap[fieldToVerify]]: true });

      // Proceed to the next step
      setCurrentStep(currentStep + 1);

      // Reset OTP state
      setOtp(new Array(4).fill(""));
      setIsOtpEntered(false);
      setFieldToVerify(""); // Clear the field being verified
    }

    setRefreshKey((prev) => prev + 1); // Increment refreshKey to trigger fetch
  };

  const handleCapture = () => {
    setIsImageCaptured(true);
    setOpenCamera(false);
    setIsModalOpen(true);
    setIsOtpEntered(true);
    setFieldToVerify("ProfileImage");
  };

  const handleUtilityUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      // const image64 = await convertToBase64(file)
      setUtilitySrc(imageUrl);
      setUtility64(file);
    }
  };

  const handleVerifyaddress = async () => {
    if (!address && !utility64) {
      toast.error("Complete address fields");
      return;
    }

    toast.loading("Loading...");

    const formData = new FormData();
    formData.append("address", address); // Add address as string
    formData.append("proof_of_address", utility64); // Add file

    try {
      const response = await addressVerification(formData, jwt);

      if (response.success) {
        setSubmit(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = () => {
    if ((address, utility64)) {
      handleVerifyaddress();
    }
    console.log({ address: address, image: utility64 });
  };

  return (
    <div className="kyc_verification_main_container">
      <ToastContainer />
      <h1>KYC Verification</h1>

      <div className="kyc_fieldstep_container_wrapper">
        <div className="kyc_fieldstep_container">
          <h2>Dollar Card KYC Verification process</h2>

          <div className="step_outer_wrapper">
            <Step currentStep={currentStep} />
          </div>

          <div className="kyc_form_wrapper">
            <div className="general_form_field">
              <label>Email:</label>
              <div className="input_button_container">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={status && status.email !== "not verified"}
                  className={
                    status && status.email !== "not verified"
                      ? "input-disabled"
                      : ""
                  }
                />
                <button
                  onClick={() => handleVerifyEmail(email)}
                  disabled={status && status.email !== "not verified"}
                  className={
                    status && status.email !== "not verified"
                      ? "button-verified"
                      : ""
                  }
                >
                  {status && status.email !== "not verified"
                    ? "Verified"
                    : "Verify"}
                </button>
              </div>
            </div>

            {status && status.email !== "not verified" && (
              <div className="general_form_field">
                <label>Phone:</label>
                <div className="input_button_container">
                  <input
                    type="text"
                    disabled={status && status.phone !== "not verified"}
                    value={phone}
                    onChange={handlePhoneChange}
                    maxLength={11}
                    className={
                      status && status.phone !== "not verified"
                        ? "input-disabled"
                        : ""
                    }
                  />
                  <button
                    onClick={() => handleVerifyPhone(phone)}
                    disabled={status && status.phone !== "not verified"}
                    className={
                      status && status.phone !== "not verified"
                        ? "button-verified"
                        : ""
                    }
                  >
                    {status && status.phone !== "not verified"
                      ? "Verified"
                      : "Verify"}
                  </button>
                </div>
              </div>
            )}

            {status && status.phone !== "not verified" && (
              <div className="general_form_field">
                <label>BVN:</label>
                <div className="input_button_container">
                  <input
                    type="text"
                    disabled={status && status.bvn !== "not verified"}
                    className={
                      status && status.bvn !== "not verified"
                        ? "input-disabled"
                        : ""
                    }
                    value={bvn}
                    maxLength={11}
                    onChange={handleBvnChange}
                  />
                  <button
                    onClick={() => handleVerifyBvn(bvn)}
                    disabled={status && status.bvn !== "not verified"}
                    className={
                      status && status.bvn !== "not verified"
                        ? "button-pending"
                        : ""
                    }
                  >
                    {status && status.bvn !== "not verified"
                      ? "Pending"
                      : "Verify"}
                  </button>
                </div>
              </div>
            )}

            {status && status.bvn !== "not verified" && (
              <div className="general_form_field">
                <label>NIN:</label>
                <div className="input_button_container">
                  <input
                    type="text"
                    disabled={status && status.nin !== "not verified"}
                    onChange={handleNinChange}
                    value={nin}
                    className={
                      status && status.nin !== "not verified"
                        ? "input-disabled"
                        : ""
                    }
                    maxLength={11}
                  />
                  <button
                    onClick={() => handleVerifyNin(nin)}
                    disabled={status && status.nin !== "not verified"}
                    className={
                      status && status.nin !== "not verified"
                        ? "button-pending"
                        : ""
                    }
                  >
                    {status && status.nin !== "not verified"
                      ? "Pending"
                      : "Verify"}
                  </button>
                </div>
              </div>
            )}

            {status && status.nin !== "not verified" && (
              <div className="general_form_field">
                <label>Image:</label>
                <div className="input_button_container">
                  <input
                    type="text"
                    id="image"
                    disabled={
                      status && status.live_face_verification !== "not verified"
                    }
                    readOnly
                    className={
                      status && status.live_face_verification !== "not verified"
                        ? "input-disabled small_file_input"
                        : "small_file_input"
                    }
                  />
                  <button
                    onClick={() => setOpenCamera(true)}
                    disabled={
                      status && status.live_face_verification !== "not verified"
                    }
                    className={
                      status && status.live_face_verification !== "not verified"
                        ? "button-pending"
                        : ""
                    }
                  >
                    {status && status.live_face_verification !== "not verified"
                      ? "Pending"
                      : "Camera"}
                  </button>
                </div>
              </div>
            )}

            {status && status.live_face_verification !== "not verified" && (
              <div className="general_form_field">
                <label>Address:</label>
                <div className="input_button_container">
                  <textarea
                    required
                    value={address}
                    disabled={
                      status && status.proof_of_address !== "not verified"
                    }
                    onChange={(e) => setAddress(e.target.value)}
                    className={
                      status && status.proof_of_address !== "not verified"
                        ? "input-disabled"
                        : ""
                    }
                  ></textarea>
                </div>
              </div>
            )}

            {status && status.live_face_verification !== "not verified" && (
              <div className="general_form_field">
                <label>
                  Upload Utility Bill{" "}
                  <span className="label_span">
                    (It must have same address with what your Provide)
                  </span>
                </label>
                <div className="input_button_container">
                  <input
                    type="file"
                    disabled={
                      status && status.proof_of_address !== "not verified"
                    }
                    required
                    className={
                      status && status.proof_of_address !== "not verified"
                        ? "input-disabled"
                        : ""
                    }
                    onChange={handleUtilityUpload}
                  />
                  {utilitySrc && (
                    <img
                      src={utilitySrc}
                      alt="utility bill"
                      style={{ height: "50px" }}
                    />
                  )}
                </div>
              </div>
            )}

            {status && status.live_face_verification !== "not verified" && (
              <button
                disabled={status && status.proof_of_address !== "not verified"}
                className="kyc_submit_btn"
                onClick={handleSubmit}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </div>

      {openCamera && <CameraField onCapture={handleCapture} jwt={jwt} />}

      {submit && <SuccessfullModal />}

      <FieldVerifyModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onOtpChange={handleOtpChange}
        otp={otp}
        onConfirmOtp={handleConfirmOtp}
        onContinue={handleContinue}
        isOtpEntered={isOtpEntered}
        setIsOtpEntered={setIsOtpEntered}
        fieldToVerify={fieldToVerify}
        fieldMsg={fieldMsg}
      />
    </div>
  );
};

export default KycVerification;
