import { useEffect, useState } from "react";
import "../MerchantModal/MerchantModal.css";
import "./CallUs.css";
import CallIcon from "../../assets/call-icon.png";
import { IoCloseCircle } from "react-icons/io5";
import { IoCallOutline } from "react-icons/io5";

const CallUs = ({ onClick }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div className="callus-modal-container">
      <div className={`callus-modal ${isVisible ? "scale-in" : "scale-out"} `}>
        <div className="merchant-modal-close-icon">
          <IoCloseCircle
            size={24}
            style={{ color: "black" }}
            onClick={() => {
              setIsVisible(false);
              setTimeout(onClick, 300);
            }}
          />
        </div>

        <h2>Call Us</h2>
        <div className="merchant-modal-section">
          <div className="merchant-modal-main">
            <div className="callus-modal-icon">
              <img src={CallIcon} alt="" />
            </div>
            <div className="merchant-modal-cardInfo">
              <h3>Call Us</h3>
              <span>Contact Call Center</span>
            </div>
          </div>
          <div className="callus-modal-button">
            <p>+234 9060898687</p>
            <button>
              <IoCallOutline size={20} /> Call now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CallUs;
