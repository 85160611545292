import { useEffect, useState } from "react";
import "./MerchantModal.css";
import MerchantIcon from "../../assets/merchant-icon.png";
import { IoCloseCircle } from "react-icons/io5";

const MerchantModal = ({ onClick }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div className="merchant-modal-container">
      <div
        className={`merchant-modal ${isVisible ? "scale-in" : "scale-out"} `}
      >
        <div className="merchant-modal-close-icon">
          <IoCloseCircle
            size={24}
            style={{ color: "black" }}
            onClick={() => {
              setIsVisible(false);
              setTimeout(onClick, 300);
            }}
          />
        </div>

        <h2>Merchants</h2>
        <div className="merchant-modal-section">
          <div className="merchant-modal-main">
            <div className="merchant-modal-icon">
              <img src={MerchantIcon} alt="" />
            </div>
            <div className="merchant-modal-cardInfo">
              <h3>Merchant</h3>
              <span>
                Become a Merchant today and start selling your products
              </span>
            </div>
          </div>
          <div className="merchant-modal-button">
            <button
              onClick={() =>
                window.open("https://merchant.leverpay.io/welcome", "_blank")
              }
              className=""
            >
              www.merchant.io
            </button>
            <span>Click on the link to get started</span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MerchantModal;

// <div className="edit-profile-container">
// <div
//   className={`edit-profile-modal-container ${
//     isVisible ? "scale-in" : "scale-out"
//   } `}
// >
//   <div className="edit-profile-close-icon">
//     <IoCloseCircle size={24} style={{ color: "black" }} />
//   </div>
//   <h3>Edit Profile</h3>

//   <form className="edit-profile-form-container">
//     <input
//       type="text"
//       name="firstName"
//       id="first-name"
//       placeholder={placeholder}
//       className=""
//     />
//     <input
//       type="text"
//       name="lastName"
//       id="last-name"
//       placeholder={placeholder}
//       className=""
//     />
//     <input
//       type="text"
//       name="otherName"
//       id="other-name"
//       placeholder={placeholder}
//       className=""
//     />
//     <input
//       type="text"
//       name="phoneNumber"
//       id="phone-number"
//       placeholder={placeholder}
//       className=""
//     />
//     <input
//       type="text"
//       name="country"
//       id="country"
//       placeholder={placeholder}
//       className=""
//     />
//     <input
//       type="text"
//       name="city"
//       id="city"
//       placeholder={placeholder}
//       className=""
//     />
//     <button className="edit-profile-form-button">Confirm</button>
//   </form>
// </div>
