import { useEffect, useState } from "react";
import "./SuccessScreen.css";
import { IoCloseCircle } from "react-icons/io5";
import SuccessIcon from "./SuccessIcon/SuccessIcon";

const SuccessScreen = ({ onClose, children }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div className="success-modal-container">
      <div className={`success-modal ${isVisible ? "scale-in" : "scale-out"}`}>
        <div className="success-modal-close-icon">
          <IoCloseCircle
            size={24}
            style={{ color: "black" }}
            onClick={() => {
              setIsVisible(false);
              setTimeout(onClose, 300);
            }}
          />
        </div>

        <div className="success-icon-display">
          <SuccessIcon />
          {/* <img src={SuccessGif} alt="" className="w-[120px]" /> */}
        </div>

        <div className="success-modal-text">
          <p>{children}</p>
        </div>
      </div>
    </div>
  );
};
export default SuccessScreen;
