import React from "react";
import LeverpayLogo from "../../../assets/images/LeverpayLogo.png";
import "./SignupOTP.css";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { verifyEmail, resendVerifyToken } from "../../../services/apiService";
import { ToastContainer, toast } from "react-toastify";

function SignupOTP({ email }) {
  const [otp, setOtp] = useState(new Array(4).fill("")); // OTP state

  const inputRef = React.createRef();

  const emailFromLocalStorage = localStorage.getItem("userEmail");

  const AccountVerify = async () => {
    let token;
    if (otp.every((digit) => digit !== "")) {
      // Move to the confirmation step
      // handleContinue();
      token = otp.join("");

      toast.loading("Verifying");

      const verifyData = {
        email: emailFromLocalStorage,
        token: `${token}`,
      };

      const response = await verifyEmail(verifyData);

      toast.dismiss();

      console.log("response", response);

      if (response.success) {
        toast.success("Email Verified");
        setTimeout(() => {
          window.location.href = "/signin";
        }, 2000);
      } else {
        toast.error(response.message);
      }
    } else {
      alert("Please enter a valid 4-digit OTP");
    }
  };

  const handleOtpChange = (e, fieldIndex) => {
    if (isNaN(e.target.value)) return false;

    console.log(otp.length, otp);

    setOtp([
      ...otp.map((data, index) =>
        index === fieldIndex ? e.target.value : data
      ),
    ]);

    if (e.target.value && e.target.nextSibling) {
      e.target.nextSibling.focus();
    }
  };

  const resendToken = async () => {
    // const email = localStorage.getItem("userEmail");
    toast.loading("Sending OTP");

    const response = await resendVerifyToken({ email: emailFromLocalStorage });

    toast.dismiss();

    toast.success("OTP Sent");
  };

  return (
    <section id="verify_email">
      <div className="logo-container">
        <img src={LeverpayLogo} alt="" className="signup-otp-logo" />
      </div>
      <div className="signup-otp-container">
        <div className="signup-otp-row">
          <p className="verify-message">Please verify your Account</p>
          <span> A code has been sent to {emailFromLocalStorage}</span>
        </div>
        <div className="otp-input-container">
          {otp.map((data, index) => (
            <input
              key={index}
              type="text"
              value={data}
              onChange={(e) => handleOtpChange(e, index)}
              maxLength={1}
              className="otp-input-value"
            />
          ))}
        </div>
        <br />
        <p className="code-notify">
          Didn't get the code?{" "}
          <button onClick={resendToken}>Click to resend</button>
        </p>

        <Button
          variant="primary"
          type="submit"
          className="verify-btn"
          disabled={otp.some((digit) => digit == "")}
          onClick={AccountVerify}
        >
          Verify Account
        </Button>
      </div>
      <ToastContainer />
    </section>
  );
}

export default SignupOTP;
