import React from 'react'
import "./SuccessfullCard.css"
import good_img from "../../../assets/images/confirm_success.png"
import { useNavigate } from 'react-router-dom'

const SuccessfullCard = ({setSuccess}) => {
  const navigate = useNavigate()

  const handleDone = () => {
    setSuccess(false)
    navigate("/")
  }

  return (
    <div className='general__backdrop'>
      <div className='general__success_card'>
        <div className='img__group'>
          <img src={good_img} alt="success logo" />
          <p>Subscription Successfull</p>
        </div>

        <div className='button__wrapper'>
          <button onClick={handleDone}>Done</button>
        </div>
      </div>
    </div>
  )
}

export default SuccessfullCard
