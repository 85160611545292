import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import style from "./ElectricityComponent.module.css"; // Choose the correct path
import { useLocalState } from "../../../utils/useLocalStorage";
import { validateCustomer, getBillerPaymentItem } from "../../../services/apiService"; // Import your API service

const providerDetails = {
  ABJPM: { id: 33948, PaydirectItemCode: "053394801" },
  "Benin Electricity Distribution Company": {
    Postpaid: { id: 646, PaydirectItemCode: 64605 },
    Prepaid: { id: 767, PaydirectItemCode: 76701 },
  },
  "Bonny Utility": { id: 3780, PaydirectItemCode: "04378001" },
  EKEDC: { id: 246, PaydirectItemCode: 24601 },
  EKEDP: { id: 1473, PaydirectItemCode: "04147302" },
  "EKO ELECTRIC": {
    Postpaid: { id: 1316, PaydirectItemCode: "04131606" },
    Prepaid: { id: 1473, PaydirectItemCode: "04147302" },
  },
  "Enugu Electricity Distribution Company": {
    Postpaid: { id: 785, PaydirectItemCode: 78599 },
    Prepaid: { id: 783, PaydirectItemCode: 78399 },
  },
  // "EEDC": {
  //   prepaid: { id: 33951, PaydirectItemCode: 8220 }
  // },
  IBEDC: {
    Postpaid: { id: 34134, PaydirectItemCode: "053413401" },
    Prepaid: { id: 34135, PaydirectItemCode: "053413501" },
  },
  "Ikeja Electric": {
    Postpaid: { id: 848, PaydirectItemCode: 84801 },
    Prepaid: { id: 766, PaydirectItemCode: 76501 },
  },
  JEDC: {
    Postpaid: { id: 2978, PaydirectItemCode: "04297801" },
    Prepaid: { id: 2979, PaydirectItemCode: "04297902" },
  },
  "JOS ELECTRIC": {
    Prepaid: { id: 33961, PaydirectItemCode: "053396101" },
  },
  "Kaduna Electricity Distribution Company": {
    Postpaid: { id: 2970, PaydirectItemCode: "04297001" },
    Prepaid: { id: 33945, PaydirectItemCode: "053394501" },
  },

  PHCN: { id: 103, PaydirectItemCode: 10301 },
  PHED: {
    Prepaid: { id: 3712, PaydirectItemCode: "04371201" },
    // Prepaid: { id: 33944, PaydirectItemCode: "053394401" },
  },
  "Yola Electricity Distribution Company": {
    prepaid: { id: 34063, PaydirectItemCode: "053406301" },
    postpaid: { id: 1753, PaydirectItemCode: "04175308" },
  },
};


const ElectricityComponent = () => {
  const [providers, setProviders] = useState([]);
  const [amount, setAmount] = useState("");
  const [provider, setProvider] = useState(null);
  const [meterNumber, setMeterNumber] = useState("");
  const [meterNumberFocused, setMeterNumberFocused] = useState(false);
  const [packageOption, setPackageOption] = useState("");
  const [availablePackages, setAvailablePackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [billerItems, setBillerItems] = useState([]); // Add state to store biller items
  const [providerError, setProviderError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [amountFocused, setAmountFocused] = useState(false);
  const [customerDetails, setCustomerDetails] = useState(null);
  const [meterError, setMeterError] = useState("");
  const [packageError, setPackageError] = useState("");
  const [amountErrorMessage, setAmountErrorMessage] = useState("");
  const [user] = useLocalState("", "user");
  const [jwt] = useLocalState("", "jwt");

  const navigate = useNavigate();

  useEffect(() => {
    const formattedProviders = Object.keys(providerDetails).map((key) => ({
      Name: key,
      Id: providerDetails[key].id,
    }));
    setProviders(formattedProviders);
  }, []);
  const fetchBillerPaymentItems = async (providerId) => {
    if (!jwt) {
      setProviderError("JWT token is missing. Please log in again");
      return [];
    }
    setIsLoading(true);
    try {
      const data = await getBillerPaymentItem(providerId, jwt);
      setBillerItems(data);
      setProviderError("");
      return data; // Return fetched data
    } catch (error) {
      setProviderError("Failed to fetch biller payment items. Please try again");
      console.error(error);
      return []; // Return empty array on error
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleMeterNumberChange = async (e) => {
    const meter = e.target.value;
    setMeterNumber(meter);

    if (meter.length >= 10 && provider && selectedPackage) {
      setIsValidating(true);
      setValidationError("");
      try {
        const validationData = await validateCustomer(
          meter,
          selectedPackage.PaydirectItemCode,
          jwt
        );

        console.log("Validation Data:", validationData);

        setCustomerDetails(validationData.Customers[0]);
        setValidationError("");
      } catch (error) {
        console.error("Error during validation:", error);
        setValidationError(
          "Failed to validate customer. Please check the Meter Number"
        );
      } finally {
        setIsValidating(false);
      }
    }
  };
  const handleAmountChange = (e) => {
    const newAmount = e.target.value.replace(/\D/g, "");
    setAmount(newAmount);
    setAmountErrorMessage("");
  };

  const handleProviderChange = (e) => {
    const selectedProviderName = e.target.value;
    const selectedProvider = providers.find(
      (p) => p.Name === selectedProviderName
    );
    setProvider(selectedProvider);

    const providerData = providerDetails[selectedProviderName];
    if (providerData) {
      const packages = Object.keys(providerData)
        .filter((key) => key !== "id")
        .map((key) => ({
          name: key,
          id: providerData[key].id,
          PaydirectItemCode: providerData[key].PaydirectItemCode,
        }));
      setAvailablePackages(packages);
      setPackageOption("");
      setSelectedPackage(null);
    } else {
      setAvailablePackages([]);
    }

    setValidationError("");
  };

  const handlePackageOptionChange = (e) => {
    const selectedPackageName = e.target.value;
    const selectedPackage = availablePackages.find(
      (pkg) => pkg.name === selectedPackageName
    );
    setPackageOption(selectedPackageName);
    setSelectedPackage(selectedPackage);

    if (selectedPackage) {
      fetchBillerPaymentItems(selectedPackage.id); // Fetch biller payment items for the selected package
    }
  };
  const handleSubmit = async () => {
    if (!provider || !meterNumber || !selectedPackage || !customerDetails) {
      setProviderError(!provider ? "Please select a provider" : "");
      return;
    }
  
    const customerEmail = localStorage.getItem("userEmail");
    const customerMobile = localStorage.getItem("userPhoneNumber");
  
    // Fetch biller payment items
    try {
      const fetchedBillerItems = await fetchBillerPaymentItems(selectedPackage.id);
  
      
      if (fetchedBillerItems && fetchedBillerItems.length > 0) {
        const billerItem = fetchedBillerItems[0];
  
        const billerData = {
          billerName: billerItem.BillerName,
          itemName: billerItem.Name,
          itemCode: billerItem.PaydirectItemCode,
          billerCategoryId: billerItem.BillerCategoryId,
          paymentCode: billerItem.PaymentCode,
          customerId: meterNumber,
          amount: amount,
          refrenceNo: billerItem.ReferenceNo,
          customerEmail,
          customerMobile,
          meterNumber,
          providerId: provider.Id,
          providerName: provider.Name,
          customerName: customerDetails?.FullName || customerDetails?.CustomerName,
          packageName: selectedPackage.name,
          packageOption: selectedPackage?.Id,
        };
  
        console.log("Biller Data:", JSON.stringify(billerData));
        localStorage.setItem("billerData", JSON.stringify(billerData));
        navigate("/electricity-payment");
      } else {
        setProviderError("No biller items found. Please select a different package.");
      }
    } catch (error) {
      console.error("Error fetching biller payment items:", error);
      setProviderError("Failed to fetch biller payment items. Please try again.");
    }
  };
  
  
  // const handleSubmit = () => {
    // if (!provider || !meterNumber || !selectedPackage || !customerDetails) {
    //   setProviderError(!provider ? "Please select a provider" : "");
    //   return;
    // }
    // const customerEmail = localStorage.getItem("userEmail");
    // const customerMobile = localStorage.getItem("userPhoneNumber");

//  const billerData = {
     
//       billerName: selectedPackage.BillerName,
//       itemName: selectedPackage.name,
//       itemCode:selectedPackage.ItemCode,
//       billerCategoryId: selectedPackage.BillerCategoryId,
//       paymentCode: selectedPackage?.PaymentCode,
//       customerId:meterNumber,
//       amount: amount,
//       refrenceNo: selectedPackage?.ReferenceNo,
//       customerEmail,
//       customerMobile,
//       meterNumber,
//       providerId: provider.Id,
//       providerName: provider.Name,
//       customerName: customerDetails?.FullName || customerDetails?.CustomerName,
//       packageName: selectedPackage.name,
//       packageOption: selectedPackage?.Id,
//     };

//     console.log("Biller Data:", JSON.stringify(billerData));

//     localStorage.setItem("billerData", JSON.stringify(billerData));
    // const billerData = {
    //   billerName: selectedPackage?.BillerName,
    //   itemName: selectedPackage?.Name,
    //   itemCode: selectedPackage?.ItemCode,
    //   billerCategoryId: selectedPackage?.BillerCategoryId,
    //   paymentCode: selectedPackage?.PaymentCode,
    //   meterNumber,
    //   providerId: provider.Id,
    //   providerName: provider.Name,
    //   customerName: customerDetails?.FullName || customerDetails?.CustomerName,
    //   packageName: selectedPackage.name,
    // };

    // console.log("Biller Data:", JSON.stringify(billerData));
    // localStorage.setItem("billerData", JSON.stringify(billerData));
  //   navigate("/electricity-payment");
  // };

  return (
    <div className={style.mainDiv}>
      <div className={style.header}>
        <FaChevronLeft
          className={style.cancelIcon}
          onClick={() => navigate(-1)}
        />
        <h2 className={style.modalTitle}>Electricity</h2>
      </div>

      <div className={style.backup}>
        <div className={style.formGroup}>
          <h1 className={style.formLabel}>Provider</h1>
          <div className={style.dropdown}>
            <select
              value={provider?.Name || ""}
              onChange={handleProviderChange}
              className={style.select}
            >
              <option value="">Select Provider</option>
              {providers.map((p) => (
                <option key={p.Name} value={p.Name}>
                  {p.Name}
                </option>
              ))}
            </select>
          </div>
          {providerError && <p className={style.errorMessage}>{providerError}</p>}
        </div>

        <div className={style.formGroup}>
          <h1 className={style.formLabel}>Package</h1>
          <div className={style.dropdown}>
            <select
              value={packageOption}
              onChange={handlePackageOptionChange}
              className={style.select}
              disabled={!provider}
            >
              <option value="">Select Package</option>
              {availablePackages.map((pkg) => (
                <option key={pkg.name} value={pkg.name}>
                  {pkg.name}
                </option>
              ))}
            </select>
          </div>
          {packageError && <p className={style.error}>{packageError}</p>}
        </div>

        <div className={style.formGroup}>
          <h1 className={style.formLabel}>Meter Number</h1>
          <input
            type="text"
            value={meterNumber}
            onChange={handleMeterNumberChange}
            onFocus={() => setMeterNumberFocused(true)}
            onBlur={() => setMeterNumberFocused(false)}
            className={`${style.input} ${
              meterNumberFocused ? style.focusedInput : ""
            }`}
            placeholder="Meter Number"
          />
          {isValidating && (
            <p className={style.loadingMessage}>Validating meter Number...</p>
          )}
          {meterError && <p className={style.errorMessage}>{meterError}</p>}
          {validationError && (
            <p className={style.errorMessage}>{validationError}</p>
          )}
          {customerDetails && (
            <p className={style.successMessage}>{customerDetails.FullName}</p>
          )}
        </div>

        <div className={style.formGroup}>
          <h1 className={style.formLabel}>Amount</h1>
          <input
            type="text"
            value={amount}
            onChange={handleAmountChange}
            onFocus={() => setAmountFocused(true)}
            onBlur={() => setAmountFocused(false)}
            className={`${style.input} ${
              amountFocused ? style.focusedInput : ""
            }`}
            placeholder="Amount"
          />
          {amountErrorMessage && (
            <p className={style.errorMessage}>{amountErrorMessage}</p>
          )}
        </div>

        <div className={style.buttonGroup}>
          <button
            type="button"
            className={style.buttonSubmit}
            onClick={handleSubmit}
          >
            Proceed
          </button>
        </div>
      </div>
    </div>
  );
};


export default ElectricityComponent;
