import React, { useEffect, useState } from "react";
import NavComponent from "../components/NavComponent/NavComponent";
import TopNav from "../components/TopNav/TopNav";
// import StatementComponent from "../components/StatementComponent/StatementComponent";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import CardcategoryPage from "./CardCategoryPage/CardcategoryPage";
import MyCardsSilver from "./SilverCardPage/MyCardsSilver";
import MyUpgradedAccount from "../components/MyUpgradedAccount/MyUpgradedAccount";
// import TotalMoney from "../components/TotalMoney/TotalMoney";
// import CardUser from "../components/AllCards/CardUserDefault";
import { MerchantComponent } from "../components/MerchantComponent/MerchantComponent";
import "./UserDashboardLayout.css";
// import Settings from "./SettingsPage/Settings";
import Settings from "../components/SettingsComponent/Settings";
import ProfilePage from "../page/ProfilePage/ProfilePage";
import HelpForm from "./HelpForm/HelpForm";
import Faq from "./FaqPage/Faq";
import Feedback from "./Feedback/Feedback";
import PaymentPage from "./PaymentPage/PaymentPage";
import ResetPassword from "../components/ResetPasswordComponent/ResetPassword";
import SignInPage from "./SignInPage/SignInPage";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
import { useLocalState } from "../utils/useLocalStorage";
import { getUserProfile } from "../services/apiService";
import { ToastContainer } from "react-toastify"; // Removed 'toast'
import TransferPage from "./TransferPage/TransferPage";
import TransferInfo from "./TransferPage/TransferInfo/TransferInfo";
import SignupPage from "./SignupPage/SignupPage";
import FundingPage from "./FundingPage/FundingPage";
import FundingPayment from "../components/AccountFunding/FundingPayment";
import FundingNaira from "../components/AccountFunding/FundingNaira";
import FundingPaystack from "../components/AccountFunding/FundingPaystack";
import FundingMore from "../components/AccountFunding/FundingMore";
import UnpaidReceipt from "../components/UnpaidInvoice/UnpaidReceipt";
import SignupOTP from "../components/SignupComponent/SignupOTP/SignupOTP";
import Investment from "./InvestmentPage/Investment";
import MySubscription from "./MySubscriptionPage/MySubscription";
import SubscriptionTransactions from "../components/MySubscriptionComponent/SubscriptionTransactions/SubscriptionTransactions";
import { CreditCard } from "./PaymentPage/CreditCard/CreditCard";
import CheckoutTransfer from "../components/CheckoutTransfer/CheckoutTransfer";
import AllInvoicePage from "./AllInvoicePage/AllInvoicePage";
import Allinvoices from "./AllInvoices/Allinvoices";
import UnpaidInvoicePage from "./UnpaidInvoicePage/UnpaidInvoicePage";
import PaidInvoice from "./InvoicePage/Invoice/PaidInvoice";
import AllTransactionCon from "./AllTransactions/AllTransactionCon";
import AirtimePage from "./PayBillsPage/AirtimePage/AirtimePage";
import DataPage from "./PayBillsPage/DataPage/DataPage";
import PayBill from "./PayBillsPage/PayBillsPage";
import AllFundingHistoryCon from "./AllTransactions/AllFundingHistoryCon";
import RecentTransactions from "./AllTransactions/RecentTransaction";
import CableTvPage from "./PayBillsPage/CableTvPage/CableTvPage";
import PinPage from "./EnterPinPage/PinPage";
import CreatePinPage from "./CreatePinPage/CreatePinPage";
import ReferralPage from "./ReferralPage/ReferralContainer";
import AirtimePaymentPage from "./PayBillsPage/AirtimePage/AirtimePaymentPage";
import DataPaymentScreen from "./PayBillsPage/DataPage/DataPaymentScreen";
import ElectricityPage from "./PayBillsPage/ElectricityPage/ElectricityPage";
import ElectricityPaymentPage from "./PayBillsPage/ElectricityPage/ElectritcityPayment";
import SuccessfullScreen from "../components/ReusableComponents/LoadingPage/SuccessfullScreen";
import TransferPayment from "./TransferPage/TransferPayment/TransferPayment";
import TransferPaymentConfirmation from "./TransferPage/TransferPaymentConfirmation/TransferPaymentConfirmation";
import CableTvPaymentScreen from "./PayBillsPage/CableTvPage/CableTvPaymentScreen";
import Vector1 from "../assets/images/Ellipse-blop2.png";
import Vector2 from "../assets/images/Ellipse-blue-blop3.png";
import Vector3 from "../assets/images/Ellipse-red-blop4.png";
import UserBalance from "../components/UserBalance/UserBalance";
import ReceiptInPDF from "./DocumentStyle/Pdf";
import ReceiptInImage from "./DocumentStyle/Image";
import ReceiptInPDF2 from "../components/ReusableComponents/Receipt/RecieptImageElect";
import ReceiptInImage2 from "../components/ReusableComponents/Receipt/RecieptImageElect";
import BillerComponent from "../components/BillerComponent/billerComponent";
import KycVerification from "./Verification/KycVerification";
import VerificationModal from "../components/Verification/Modal/VerificationModal";
import VirtualCard from "../components/VirtualCardComponent/VirtualCard";
import InternetPage from "./PayBillsPage/InternetPage/InternetPage";
import BundlePage from "./PayBillsPage/InternetPage/BundlePage";

export const UserDashboardLayout = (props) => {
  const [user, setUser] = useState({});
  const [jwt, setJwt] = useLocalState("", "jwt");
  // const userJson = localStorage.getItem("user");
  // const userData = JSON.parse(userJson);
  const [showVerificationModal, setShowVerificationModal] = useState(false);

  useEffect(() => {
    getUserProfile(jwt, setJwt, setUser);
  }, [jwt, setJwt]);

  const handleCardClick = () => {
    setShowVerificationModal(true);
  };

  const closeModal = () => {
    setShowVerificationModal(false);
  };

  return (
    <>
      <Routes>
        <Route path="/investment" element={<Investment />} />
        <Route path="signin" element={<SignInPage />} />
        <Route path="leverpay-signup" element={<SignupPage />} />
        <Route path="leverpay-signup/signup-OTP" element={<SignupOTP />} />
        <Route
          element={
            <div className="dashboard_container_wrapper">
              {showVerificationModal && (
                <VerificationModal onClose={closeModal} />
              )}
              <div className="dashboard_content_wrapper">
                <NavComponent openKycModal={handleCardClick} />
              </div>
              <div className="dashboard_body_container_wrapper">
                <div className="dashboard_header_container_wrapper">
                  <TopNav
                    userName={{
                      firstName: user.first_name,
                      lastName: user.last_name,
                      passport: user.passport,
                    }}
                  />
                </div>
                <div className="dashboard_body_content_wrapper">
                  <PrivateRoute userName={user.first_name}>
                    <Outlet />
                  </PrivateRoute>
                </div>
              </div>
            </div>
          }
        >
          <Route
            path="/"
            element={
              <div className="dashboard">
                <div className="dashboard-right">
                  <div className="spending">
                    <UserBalance />
                  </div>
                  <div className="dashboard-transaction-table-container">
                    <RecentTransactions />
                  </div>
                </div>
                <div className="dashboard-left">
                  <div>
                    <BillerComponent />
                  </div>
                  {/* <div className="default-card-holder">
                    <header className="card-header">My Card</header>
                    <CardUser />
                  </div> */}
                  <div className="mchnt-con col-md-12">
                    <MerchantComponent />
                  </div>
                </div>
                <img src={Vector1} alt="" className="vector-blop1" />
                <img src={Vector2} alt="" className="vector-blop2" />
                <img src={Vector3} alt="" className="vector-blop3" />
              </div>
            }
          />
          <Route path="transactions" element={<AllTransactionCon />} />
          <Route path="funding-history" element={<AllFundingHistoryCon />} />
          <Route exact path="transfer" element={<TransferPage />} />
          <Route path="/transfer-info" element={<TransferInfo />} />
          <Route path="/transfer-payment" element={<TransferPayment />} />
          <Route
            path="/transfer-payment-confirmation"
            element={<TransferPaymentConfirmation />}
          />
          <Route exact path="/investment" element={<Investment />} />
          <Route path="invoices" element={<AllInvoicePage />}>
            <Route index element={<Allinvoices />} />
            <Route path="all-invoices" element={<Allinvoices />} />
            <Route path="unpaid-invoices" element={<UnpaidInvoicePage />} />
            <Route path="paid-invoice" element={<PaidInvoice />} />
          </Route>
          <Route path="/unpaid-invoice" element={<UnpaidReceipt />} />
          <Route path="funding" element={<FundingPage />}>
            <Route index element={<FundingPayment />} />
            <Route path="stablecoins-deposit" element={<FundingPayment />} />
            <Route path="naira-deposit" element={<FundingNaira />} />
            <Route path="paystack-deposit" element={<FundingPaystack />} />
            <Route path="more" element={<FundingMore />} />
          </Route>
          <Route path="cardCategories" element={<CardcategoryPage />} />
          <Route path="account" element={<MyUpgradedAccount />} />
          <Route path="kyc-verification" element={<KycVerification />} />
          <Route path="my-cards" element={<VirtualCard />} />
          {/* <Route path="settings" element={<Settings />} /> */}
          <Route
            path="settings"
            element={
              <Settings
                userName={{
                  firstName: user.first_name,
                  lastName: user.last_name,
                  email: user.email,
                  passport: user.passport,
                }}
              />
            }
          />
          <Route
            path="profile"
            element={
              <ProfilePage
                userName={{
                  firstName: user.first_name,
                  lastName: user.last_name,
                  otherName: user.other_name,
                  gender: user.gender,
                  reg_email: user.email,
                  reg_phone: user.phone,
                  state: user.state?.state_name,
                }}
              />
            }
          />
          <Route path="referral" element={<ReferralPage />} />
          <Route path="help" element={<HelpForm />} />
          <Route path="faq" element={<Faq />} />
          <Route path="customer-support" element={<Feedback />} />
          <Route path="payment-page" element={<PaymentPage />}>
            <Route index element={<CreditCard />} />
            <Route path="credit-card" element={<CreditCard />} />
            <Route
              path="checkout-transfer"
              element={<CheckoutTransfer isInvest={true} />}
            />
          </Route>
          <Route path="/my-subscriptions" element={<MySubscription />} />
          <Route
            path="my-subscriptions/subscription-transactions"
            element={<SubscriptionTransactions />}
          />
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/paybills" element={<PayBill />} />
          <Route path="/airtime" element={<AirtimePage />} />
          <Route path="/airtime-payment" element={<AirtimePaymentPage />} />
          <Route path="/success" element={<SuccessfullScreen />} />
          <Route path="/data" element={<DataPage />} />
          <Route path="/data-payment" element={<DataPaymentScreen />} />
          <Route path="/cable-tv" element={<CableTvPage />} />
          <Route path="/cable-tv-payment" element={<CableTvPaymentScreen />} />
          <Route path="/electricity" element={<ElectricityPage />} />
          <Route path="/internet" element={<InternetPage />} />
          <Route path="/internet/bundle/:id" element={<BundlePage />} />
          <Route
            path="/electricity-payment"
            element={<ElectricityPaymentPage />}
          />
          <Route path="/pin" element={<PinPage />} />
          <Route path="/create-pin" element={<CreatePinPage />} />
          <Route path="/receipt/pdf" element={<ReceiptInPDF />} />
          <Route path="/receipt/image" element={<ReceiptInImage />} />
          <Route path="/receipt/electricity/pdf" element={<ReceiptInPDF2 />} />
          <Route
            path="/receipt/electricity/image"
            element={<ReceiptInImage2 />}
          />
        </Route>
      </Routes>
      <ToastContainer />
    </>
  );
};

export default UserDashboardLayout;
