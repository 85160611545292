import { useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import "./InternetBundle.css";
import { useNavigate } from "react-router-dom";
import SuccessfullCard from "./SuccessfullCard";
import FailedCard from "./FailedCard";
import { toast } from "react-toastify";

const InternetBundle = ({ bundle }) => {
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false)
  const [fail, setFail] = useState(false)
  const [pin, setPin] = useState()

  const handleConfirm = () => {
    if(pin.length !== 4){
      toast.error("Enter four digit pin")
      return
    }
  }

  return (
    <div className="bundle_main_card_container">
      {success && <SuccessfullCard setSuccess={setSuccess} />}
      {fail && <FailedCard setFail={setFail} />}

      <div className="transfer-header">
        <button onClick={() => navigate(-1)}>
          <IoIosArrowBack />
        </button>
        <h1>Bundle Details</h1>
        <div className="not-needed"></div>
      </div>

      <div className="bundle_options_card_container">
        <h2>Bundle Details</h2>

        <div className="short_details_card">
          <h3>{bundle && bundle.Name}</h3>
          <p>Get 1000GB Xtraspecial plan for N1160,000. Valid for 30 days</p>
        </div>

        <div className="bundle_summary_card">
          <h3>Summary Details</h3>

          <div className="group_summary">
            <div className="individual_summary">
              <p className="summary_heading">Provider:</p>
              <p className="summary_value">Starlink</p>
            </div>
            <div className="individual_summary">
              <p className="summary_heading">Package:</p>
              <p className="summary_value">Monthly Exclusive</p>
            </div>
            <div className="individual_summary">
              <p className="summary_heading">Company:</p>
              <p className="summary_value">Nanstang Tech</p>
            </div>
          </div>

          <div className="summary_input_group">
            <label htmlFor="">Transaction Pin</label>
            <input type="text" name="" id="" placeholder="44569*" />
            <button onClick={handleConfirm}>Confirm</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InternetBundle;
